<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/公司简介.jpg" lazy width="100%" height="100%">

    <div class="size_box">
      <img src="../../../../public/images/底部导航/实力+宗旨+发展历程/公司实力.jpg" lazy width="100%" height="100%">
<!--      <div class="title">公司简介</div>-->
      <h3>智驹公司简介</h3>
      <p>深圳市智驹科技有限公司成立于2015年，是一家以视频为核心技术，集研发、生产、销售和服务于一体的国家级高新技术企业。</p>
      <p>智驹致力于视频安全技术、智能物联技术和云平台服务，为全国30多个省市提供全面的视频安全产品解决方案。智驹拥有音视频编解码、视频图像处理、音视频数据存储、P2P数据传输等核心技术以及云计算、大数据等数十项专利技术。针对网络摄像机、低功耗摄像机、智能家居等智能音视频类产品提供最专业、最安全、最便捷、最智能的产品和服务。</p>
      <p>智驹是全国视频监控数字化、网络化、高清化、智能化的实践者和见证者。经过多年的技术沉淀和稳健发展，坚持以视频为核心技术的安全类产品已涵盖家庭安防、智能店铺、智能家居等相关领域，为可持续发展创造良好的条件。</p>
      <p>智驹秉承“专业、诚信、创新、共赢”的经营理念，坚持将“社会的安全，智驹的责任”为行动准则，不断发展技术，服务全人类。</p>




      <h3>公司宗旨</h3>
      <p>目标：成为智能家居安防领域最可靠、最受尊敬的品牌之一。</p>
      <p>任务：为每个人创造安全、方便和聪明的生活。</p>
      <p>价值观：专注 创新 共赢</p>

      <h3>公司实力</h3>
      <p>行业领先：领先于业内的智能硬件解决方案提供商</p>
      <p>名列前三：IPC国内市场占有率名列前3</p>
      <p>多领域：产品涵盖民用视频监控、智能安防、智能家居</p>
    </div>
    <img src="../../../../public/images/底部导航/实力+宗旨+发展历程/历程.jpg" lazy width="100%" height="100%" style="margin-top: 100px">
  </div>
</template>

<script>
export default {
  name: "公司简介"
}
</script>

<style scoped>
.size_box{
  width: 80%;
  margin: auto;
}
.title{
  text-align: center;
  font-size:24px;
  margin-top: 50px;
}
p{
  margin-bottom: 20px;
  text-indent: 2em;
}
</style>